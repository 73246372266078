import {is_object, convertImgToBase64} from "@/components/base/utils";
const BaseMultiTagComponent = () => import("@/components/base/tag/BaseMultiTagComponent")
import BaseTextComponent from "@/components/base/el/BaseTextComponent";
import {apiBlog} from "@/apis/blogs";
import {api} from "@/apis/api";


export default {
    name: "BaseDetailBlogComponent",
    mixins: [BaseTextComponent],
    data() {
        return {
            is_text: false,
        };
    },
    created() {
        let url = new URL(window.parent.location.href)
        let search_params = new URLSearchParams(url.search)
        if (this.$store.state.common_data.blog_from_api) {
            apiBlog({url: search_params.get("blog"), limit: 1}).then((response) => {
                this.$store.state.blog_detail = response.data.posts[0]
                if (!search_params.get("blog")) {
                    apiBlog({url: response.data.posts[0].url.substring(1), limit: 1}).then((response) => {
                        this.$store.state.blog_detail = response.data.posts[0]
                    })
                }
            })
        }
    },
    render(createElement) {
        return this.make_element_tree(createElement, this.component_data);
    },
    methods: {
        async save(values) {
            console.log(values);
            if (values.cover_img) {
                convertImgToBase64(values.cover_img, (data) => {
                    api({
                        jsonrpc: "2.0",
                        model: "blog.post",
                        method: 'call',
                        params: {
                            model: 'blog.post',
                            method: 'write',
                            args: [[this.$store.state.blog_detail.id], {cover_img: data.split(",")[1]}]
                        }
                    }).then(() => this.$store.state.blog_detail.image = data)
                })

            } else {
                api({
                    jsonrpc: "2.0",
                    model: "blog.post",
                    method: 'call',
                    params: {
                        model: 'blog.post',
                        method: 'write',
                        args: [[this.$store.state.blog_detail.id], values]
                    }
                })
                    .then(() => {
                        this.$store.state.blog_detail.content = values.content
                    })
            }
        },
        get_el_props(element_data, context) {
            let el = element_data.tag
            let props = null

            if (element_data.attr && 'deepsel-multi-tag' in element_data.attr) {
                el = BaseMultiTagComponent
                props = {
                    component_data: element_data,
                    tag_data: context.tag_data
                }
            }

            return [el, props]
        },
        get_attrs(element_data) {
            if (element_data.attr) {
                let attrs = {}

                for (let key in element_data.attr) {
                    if (key === 'style' && is_object(element_data.attr[key])) continue
                    let value = element_data.attr[key];
                    attrs[key] = Array.isArray(value) ? value.join(" ") : value
                }
                if ('deepsel-detail-blog-image' in attrs) {
                    if (attrs.src && this.$store.state.blog_detail && this.$store.state.blog_detail.image) attrs.src = this.$store.state.blog_detail.image
                } else if ('deepsel-detail-blog-url' in attrs) {
                    attrs.href = `/blog?blog=${this.$store.state.blog_detail.url}`
                    
                    // if(this.$store.state.edit_mode) delete attrs.href  // detele href in edit_mode
                }

                return attrs
            } else return null
        },
        get_style(element_data) {
            if (element_data.attr && is_object(element_data.attr.style)) {
                let origin = this.$store.state.origin;
                let style = JSON.parse(JSON.stringify(element_data.attr.style))

                if (style.backgroundImage) {
                    if ('deepsel-blog-image' in element_data.attr) {
                        style.backgroundImage = `url(${this.$store.state.blog_detail.image})`
                    } else if (
                        !style.backgroundImage.includes("https://") &&
                        !style.backgroundImage.includes("http://")
                    ) {
                        let background_img_path = style.backgroundImage
                            .replace('url(', '')
                            .replace(')', '')
                            .replace(/'/g, '')

                        // background_img_path = background_img_path.replace(/\\/g, " ")
                        if (background_img_path.slice(0, 1) !== "/") {
                            background_img_path = "/" + background_img_path;
                        }

                        style.backgroundImage = `url(${origin + background_img_path})`
                    }
                }
                return style
            } else return null
        },
        get_children(element_data, createElement) {
            let children = []
            let deepsel_text_data = null
            if (element_data.attr && this.$store.state.blog_detail) {
                if ('deepsel-detail-blog-title' in element_data.attr) deepsel_text_data = this.$store.state.blog_detail.title
                else if ('deepsel-detail-blog-subtitle' in element_data.attr) deepsel_text_data = this.$store.state.blog_detail.subtitle
                else if ('deepsel-detail-blog-author' in element_data.attr) deepsel_text_data = this.$store.state.blog_detail.author
                else if ('deepsel-detail-blog-date' in element_data.attr) deepsel_text_data = this.$moment(this.$store.state.blog_detail.publish_date).format('llll')
                else if ('deepsel-detail-blog-category' in element_data.attr) deepsel_text_data = this.$store.state.blog_detail.category
            }

            if (element_data.child) {
                for (let item of element_data.child) {
                    if (item.node === "element") {

                        let single_tag_children = false
                        if (item.attr && 'deepsel-multi-tag' in item.attr) {
                            if (single_tag_children) continue
                            single_tag_children = true
                            children.push(this.make_element_tree(createElement, item, {}, {tag_data: this.$store.state.blog_detail.tag}))

                        } else {
                            children.push(this.make_element_tree(createElement, item));
                        }

                    } else if (item.node === "text") {
                        children.push(deepsel_text_data ? deepsel_text_data : item.text)
                    }
                }
            }
            return children
        },
        focus_out() {
        },
        make_element_tree(createElement, element_data, options = {}, context = null) {
            if (!this.$store.state.blog_detail) return createElement('div', 'Không tìm thấy bài viết nào')
            let data = {}
            let el, props, attrs, style, children

            if (element_data && element_data.attr && 'deepsel-detail-blog' in element_data.attr) {
                let class_array = [{"deepsel-focus": this.is_editing}]
                if (this.component_data.attr && this.component_data.attr.class) {
                    let class_value

                    if (Array.isArray(this.component_data.attr.class)) {
                        class_value = this.component_data.attr.class.join(" ")
                    } else class_value = this.component_data.attr.class

                    class_array.push(class_value)
                }
                data = {
                    class: class_array,
                    attrs: {
                        contenteditable: this.$store.state.edit_mode && !this.component_data.attr['deepsel-esc'],
                        draggable: false,
                    },
                    style: this.styling,
                    on: {
                        focusout: this.focus_out,
                        // input: this.on_input,
                        mouseenter: this.mouse_enter,
                        mousedown: this.mouse_down,
                    },
                }
            }

            [el, props] = this.get_el_props(element_data, context)
            attrs = this.get_attrs(element_data)
            children = this.get_children(element_data, createElement)
            style = this.get_style(element_data)

            if (props) data.props = props
            if (attrs) data.attrs = {...attrs, ...data.attrs}
            if (style) data.style = {...style, ...data.style}

            if(element_data.attr && 'deepsel-detail-blog-content' in element_data.attr) {
                data = { 
                    domProps: {
                        innerHTML: this.$store.state.blog_detail && this.$store.state.blog_detail.content ? this.$store.state.blog_detail.content : '',
                    },
                    attrs
                }
            }

            for (let key in options) data[key] = options[key]

            return createElement(
                el,
                data,
                children
            )
        },

        replace_origin_urls(attrs, element_data) {
            let origin = this.$store.state.origin;
            if (origin) {
                if (element_data.tag === "script" || element_data.tag === "img") {
                    if (attrs.src) {
                        if (
                            !attrs.src.includes("https://") &&
                            !attrs.src.includes("http://")
                        ) {
                            attrs.src = attrs.src.replace(/\\/g, "/");
                            if (attrs.src.slice(0, 1) !== "/") {
                                attrs.src = "/" + attrs.src;
                            }
                            attrs.src = origin + attrs.src;
                        }
                    }
                } else if (element_data.tag === "link") {
                    if (
                        !attrs.href.includes("https://") &&
                        !attrs.href.includes("http://") &&
                        attrs.href !== '#' &&
                        !attrs.href.includes('javascript:void')
                    ) {
                        attrs.href = attrs.href.replace(/\\/g, "/");
                        if (attrs.href.slice(0, 1) !== "/") {
                            attrs.href = "/" + attrs.href;
                        }
                        attrs.href = origin + attrs.href;
                    }
                }
            }
        },
    },
    computed: {
        data_object() {
            let class_array = [{"deepsel-focus": this.is_editing}]
            if (this.component_data.attr && this.component_data.attr.class) {
                let class_value

                if (Array.isArray(this.component_data.attr.class)) {
                    class_value = this.component_data.attr.class.join(" ")
                } else class_value = this.component_data.attr.class

                class_array.push(class_value)
            }

            return {
                class: class_array,
                attrs: {
                    contenteditable: this.$store.state.edit_mode && !this.component_data.attr['deepsel-esc'],
                    draggable: false,
                },
                style: this.styling,
                on: {
                    focusout: this.focus_out,
                    // input: this.on_input,
                    mouseenter: this.mouse_enter,
                    mousedown: this.mouse_down,
                },
            };
        },
    },
    
}
