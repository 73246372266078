import request from '@/utils/request'

const apiBlog = function (data) {
  console.log(data);
  return request({
    url: '/blog.json',
    method: 'get',
    params: data
  })
}
const apiSearchResult = function (data) {
  // console.log(data);
  return request({
    url: '/search.json',
    method: 'get',
    params: data
  })
}

export {
  apiBlog,
  apiSearchResult
}

